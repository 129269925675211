// React
import React from 'react';
import { Link } from 'react-router-dom';

import { Authenticator } from '@aws-amplify/ui-react';

// Imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { View, Image, Text, useTheme } from '@aws-amplify/ui-react';
import { ThemeProvider, createTheme } from '@mui/material';

export default function AppMenu() {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (

        <>
            <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>
                <Typography variant="h6">
                    Date Spotters Dashboard
                </Typography>
            </Link>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

            <div>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to='/' onClick={handleClose}>Home</MenuItem>
                    <MenuItem component={Link} to='/cityselect' onClick={handleClose}>Select City</MenuItem>
                    <MenuItem component={Link} to='/blog' onClick={handleClose}>Blog</MenuItem>
                    <MenuItem component={Link} to='/about' onClick={handleClose}>About</MenuItem>
                    <MenuItem component={Link} to='/profile' onClick={handleClose}>Profile</MenuItem>
                </Menu>
            </div>
        </>

    );
}