import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from "../../components/NavBar.js";
import Footer from "../../components/Footer.js";
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
    CardMedia,
    CardContent
} from '@mui/material/';
import * as queries from '../../graphql/queries';
import { API, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";


function AdminHome() {

    const [user, setUser] = useState({});
    const [authorized, setAuthorized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkUser() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const powerUserData = await API.graphql({ query: queries.listPowerUsers });
                const temp = powerUserData.data.listPowerUsers.items;
                let allowed = false;
                for (let i = 0; i < temp.length; i++) {
                    if (user.attributes.sub === temp[i].cognitoSub) {
                        setAuthorized(true);
                        allowed = true;
                        setUser(temp[i]);
                        return
                    }
                }
                if (!allowed) {
                    navigate('/');
                }
            } catch (err) {
                console.log({ err })
            }
        }
        checkUser()
    }, [])

    return (
        <Authenticator>
            {({ signOut, user }) => (
                <main>
                    <NavBar/>
                    <Container maxWidth="sm" align='center' sx={{ pb: 5 }}>

                        <Typography variant='h2' sx={{ pt: 4, pb: 2 }}>
                            Welcome. What are you trying to do?
                        </Typography>
                        <Typography variant='h6' sx={{ pt: 2, pb: 5 }}>
                            This page is used by the creator of Date Spotters. If you have found yourself here, please let him know at stepoutdateapp@gmail.com
                        </Typography>

                        <Stack spacing={2} direction='column'>
                            <Link to='/admin-xyaddlocation' style={{ textDecoration: 'none' }}>
                                <Button sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Add Location</Button>
                            </Link>
                            <Link to='/admin-xyaddcity' style={{ textDecoration: 'none' }}>
                                <Button sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Add City</Button>
                            </Link>
                        </Stack>
                    </Container>
                </main>
            )}
        </Authenticator>
    );
}

export default AdminHome;