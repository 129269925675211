import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from "../../components/NavBar.js";
import Footer from "../../components/Footer.js";
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
    CardMedia,
    CardContent,
    Modal,
    TextField
} from '@mui/material/';
import * as queries from '../../graphql/queries.js';
import { API, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';


function EditProfile() {

    const [user, setUser] = useState({});
    const [newEmail, setNewEmail] = useState('');

    useEffect(() => {
        async function getUser() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user);
            } catch (err) {
                console.log({ err })
            }
        }
        getUser()
    }, [])


    async function save() {
        try {
            const result = await Auth.updateUserAttributes(user, {
                email: newEmail,
            });
        } catch (err) {
            console.log('error: ', err);
        }
    }

    return (
        <>
            <NavBar />
            <Authenticator>
                {({ signOut, user }) => (
                    <main>
                        <Container maxWidth='md' align='left'>
                            <Typography variant='h2' sx={{ pt: 4, pb: 2 }}>
                                Edit Profile
                            </Typography>

                            <Stack direction='column' spacing={2}>
                                {/* Email */}
                                <TextField
                                    placeholder="Email"
                                    name="email"
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    color='secondary'
                                    sx={{ pb: 4 }}
                                />
                            </Stack>

                            <Stack direction='row' spacing={2}>
                                <Link to='/profile' style={{ textDecoration: 'none' }}>
                                    <Button style={{width: '100%'}} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained' color='secondary'>Cancel</Button>
                                </Link>
                                <Button onClick={save} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Save</Button>
                            </Stack>
                        </Container>
                    </main>
                )}
            </Authenticator>
            <Footer />
        </>
    );
}

export default EditProfile;