import React, { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';

import { useTheme } from '@mui/material/styles';

import NavBar from "../components/NavBar.js";
import Footer from "../components/Footer.js";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { useMediaQuery } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

import { FormLabel, Chip, FormControlLabel, Radio, Modal, RadioGroup, Stack, Button, Typography, Paper, Grid, Card, Container, Box, InputLabel, MenuItem, FormControl, FormHelperText, CircularProgress } from '@mui/material/';
import Select from '@mui/material/Select';

import { ToggleButtonGroup, ToggleButton } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';

import { Link } from 'react-router-dom';

import Results from '../components/Results.js';


let myIcon = L.icon({
    iconUrl: require('../assets/icons8-location-30.png'),
    shadowUrl: iconShadow,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    shadowAnchor: [12.7, 40],
    popupAnchor: [0, -25]
});

L.Marker.prototype.options.icon = myIcon;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const top100Films = [
    { title: '$' },
    { title: '$$' },
    { title: '$$$' },
    { title: '$$$$' },
];

function MapPage() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [filterLocations, setFilterLocations] = useState([]);
    // const position = [38.8840881, -77.0931851];
    const [city, setCity] = useState({});
    const query = new URLSearchParams(window.location.search);
    const cityID = query.get("city")

    // FILTERS
    const [alcohol, setAlcohol] = useState('');
    const [price, setPrice] = useState('');
    const [selectedPriceRanges, setSelectedPriceRanges] = useState([]);
    const [indoorOutdoor, setIndoorOutdoor] = useState('');
    const [active, setActive] = useState('');

    // CHIPS
    const [restaurantChip, setRestaurantChip] = useState(false);
    const [barChip, setBarChip] = useState(false);

    // CHIPS CURRENTLY SELECTED (TRUE)
    const [selectedTags, setSelectedTags] = useState([]);

    // MODAL
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        async function fetchLocations() {
            try {
                const locationData = await API.graphql({ query: queries.listLocations });
                const temp = locationData.data.listLocations.items;
                setLocations(temp);
                setFilterLocations(temp);
            } catch (err) {
                console.log({ err })
            }
        }
        async function fetchCity() {
            try {
                const oneCity = await API.graphql({
                    query: queries.getCity,
                    variables: { id: cityID }
                });
                const temp = oneCity.data.getCity;
                setCity(temp);
                setLoading(false);
            } catch (err) {
                console.log({ err })
            }
        }
        fetchLocations();
        fetchCity();
    }, [])

    async function updateLocations() {
        let newLocations = [];

        //
        // The if statement here looks like a lot. Each line (except for price) has 3 things it checks.
        // We will use user input = INPUT and the value for the location as VALUE
        // 1. The beginning checks if INPUT = VALUE for that location
        //      a. It also checks if INPUT is empty, for which we keep the location on the map
        // 2. We check if INPUT is yes, and VALUE is either (it should be on the map even though its not an exact match)
        // 3. We do the same for the opposite INPUT and a VALUE of either
        //
        //
        for (let i = 0; i < locations.length; i++) {
            let temp = locations[i]
            //console.log(selectedPriceRanges)
            const priceCheck = selectedPriceRanges.indexOf(temp.price);
            if (
                // CHECK INITIAL FILTERs
                // Alcohol
                ((temp.alcohol.includes(alcohol) || temp.alcohol === '' || temp.alcohol === null) || (alcohol === 'Either') || ((alcohol === 'Yes') && (temp.alcohol === 'Either')) || ((alcohol === 'No') && (temp.alcohol === 'Either'))) &&
                // Active
                ((temp.active.includes(active) || temp.active === '' || temp.active === null) || (active === 'Either') || ((active === 'Yes') && (temp.active === 'Either')) || ((active === 'No') && (temp.active === 'Either'))) &&
                // Indoor/Outdoor
                ((temp.indoorOutdoor.includes(indoorOutdoor) || temp.indoorOutdoor === '' || temp.indoorOutdoor === null) || (indoorOutdoor === 'Either') || ((indoorOutdoor === 'Indoor') && (temp.indoorOutdoor === 'Either')) || ((indoorOutdoor === 'Outdoor') && (temp.indoorOutdoor === 'Either'))) &&
                // Price
                // ((temp.price.includes(price) || temp.price === '' || temp.price === null))
                (((priceCheck >= 0) || temp.price === '' || temp.price === null || selectedPriceRanges.length === 0 || selectedPriceRanges === null))
            ) {

                if (restaurantChip && !temp.tags.includes('Restaurant')) {
                    continue;
                }
                if (barChip && !temp.tags.includes('Bar')) {
                    continue;
                }
                newLocations.push(locations[i]);
                //console.log(temp.tags)
            }
        }

        setFilterLocations(newLocations);
        handleClose()

    }

    async function alcoholChange(e) {
        setAlcohol(e);
    }

    async function priceChange(e) {
        setPrice(e);
    }

    const handleToggleButtonClick = (event) => {
        const priceRange = event.target.value;

        if (selectedPriceRanges.includes(priceRange)) {
            setSelectedPriceRanges(selectedPriceRanges.filter((range) => range !== priceRange));
        } else {
            setSelectedPriceRanges([...selectedPriceRanges, priceRange]);
        }
    };

    async function activeChange(e) {
        setActive(e);
    }

    async function indoorOutdoorChange(e) {
        //console.log(e);
        setIndoorOutdoor(e);
    }

    async function reset() {
        setFilterLocations(locations);
        setAlcohol('');
        setPrice('');
        setActive('');
        setIndoorOutdoor('');
        setRestaurantChip(false);
        setBarChip(false);
        setSelectedPriceRanges([]);
        handleClose();
    }

    // RESTAURANT
    //
    const handleRestaurantChipClick = (s) => {
        setRestaurantChip(s);
        let tempArray = selectedTags;
        if (s) {
            // Add to active tags
            tempArray.push('Restaurant');
            setSelectedTags(tempArray);
            return;
        }
        // Remove from active tags
        let tempArray2 = tempArray.filter((item) => item !== 'Restaurant')
        setSelectedTags(tempArray2);
    };

    // BAR
    //
    const handleBarChipClick = (s) => {
        setBarChip(s);
        let tempArray = selectedTags;
        if (s) {
            // Add to active tags
            tempArray.push('Bar');
            setSelectedTags(tempArray);
            return;
        }
        // Remove from active tags
        let tempArray2 = tempArray.filter((item) => item !== 'Bar')
        setSelectedTags(tempArray2);
    };

    if (loading) return <>
        <NavBar />
        <Container sx={{ pt: 10, pb: 10 }} align='center'>
            <CircularProgress justifyContent="center" align="center" />
        </Container>
        <Footer />
    </>
    return (
        <>
            <NavBar />
            <Container sx={{pt: 1}} align='right'>
                <Typography variant='p1' sx={{pr: 1}}>Filters:  </Typography>
                <Button variant='contained' color='secondary' alignItems='right' justifyContent='right'>
                    <FilterAltIcon onClick={handleOpen} align='right' fontSize='medium' color='white' />
                </Button>
            </Container>
            <Container sx={{ pt: 1 }} maxWidth='xlg'>
                {/* <FilterForm/> */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Container sx={{ pb: 1, pt: 4 }}>
                        <Paper elevation={3} style={{ background: '#F4F4F4', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Container>
                                <Grid direction='column' spacing={1} justifyContent="center" alignItems="center" style={{ height: '100%' }} sx={{ pt: .5, pb: 1 }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" align="center" padding={1}>
                                            Set Filters
                                        </Typography>
                                    </Grid>
                                    <Grid item spacing={.5} xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                                        <Grid container justifyContent="center" spacing={3} sx={{ pl: 2, pr: 2 }}>
                                            {/* Grids go here */}
                                            <Grid item xs={12} sm={12} md={3} lg={3} justifyContent="center" align='center'>
                                                <FormControl>
                                                    <ToggleButtonGroup value={selectedPriceRanges} onChange={handleToggleButtonClick}>
                                                        <ToggleButton value="1" aria-label="Dollar sign">
                                                            $
                                                        </ToggleButton>
                                                        <ToggleButton value="2" aria-label="Two dollar signs">
                                                            $$
                                                        </ToggleButton>
                                                        <ToggleButton value="3" aria-label="Three dollar signs">
                                                            $$$
                                                        </ToggleButton>
                                                        <ToggleButton value="4" aria-label="Four dollar signs">
                                                            $$$$
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <FormControl fullWidth color='secondary'>
                                                    <InputLabel id="demo-simple-select-label" color='secondary'>Alcohol?</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={alcohol}
                                                        label="Alcohol"
                                                        onChange={(e) => alcoholChange(e.target.value)}
                                                        // style={{ width: 150 }}
                                                        color='secondary'
                                                    >
                                                        <MenuItem color='secondary' value={"Yes"}>Yes</MenuItem>
                                                        <MenuItem color='secondary' value={"No"}>No</MenuItem>
                                                        <MenuItem color='secondary' value={"Either"}>Either</MenuItem>
                                                    </Select>
                                                    <FormHelperText>Do you intend to drink?</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                                <FormControl>
                                                    <FormLabel id="radio-buttons-active-label" color='secondary'>
                                                        <Grid container direction="row" alignItems="center">
                                                            <Grid item>
                                                                Active?
                                                            </Grid>
                                                            <Grid item>
                                                                <DirectionsRunIcon style={{ alignItems: 'center' }} />
                                                            </Grid>
                                                        </Grid>
                                                    </FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="radio-buttons-active-label"
                                                        // defaultValue="Either"
                                                        name="radio-buttons-group"
                                                        onChange={(e) => activeChange(e.target.value)}
                                                        value={active}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio color='secondary' />} label="Yes" />
                                                        <FormControlLabel value="No" control={<Radio color='secondary' />} label="No" />
                                                        <FormControlLabel value="Either" control={<Radio color='secondary' />} label="Either" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                                <FormControl>
                                                    <FormLabel id="radio-buttons-indoorOutdoor-label" color='secondary'>Indoor/Outdoor</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="radio-buttons-indoorOutdoor-label"
                                                        // defaultValue="Either"
                                                        name="radio-buttons-group"
                                                        onChange={(e) => indoorOutdoorChange(e.target.value)}
                                                        value={indoorOutdoor}
                                                    >
                                                        <FormControlLabel value="Indoor" control={<Radio color='secondary' />} label="Indoor" />
                                                        <FormControlLabel value="Outdoor" control={<Radio color='secondary' />} label="Outdoor" />
                                                        <FormControlLabel value="Either" control={<Radio color='secondary' />} label="Either" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Container alignItems='center' justifyContent='center' sx={{ pb: 1 }}>
                                    <Stack direction="row" spacing={1} align='center' justifyContent='center' sx={{ pb: 3 }}>
                                        <Chip size='small' label="Restaurant" onClick={() => handleRestaurantChipClick(!restaurantChip)} color={restaurantChip ? "secondary" : "default"} />
                                        <Chip size='small' label="Bar" onClick={() => handleBarChipClick(!barChip)} color={barChip ? "secondary" : "default"} />
                                    </Stack>
                                    <Stack direction='row' spacing={3} justifyContent='center'>
                                        <Button align='center' variant='contained' onClick={reset}>
                                            Reset
                                        </Button>
                                        <Button align='center' variant='contained' onClick={updateLocations}>
                                            Apply Filter
                                        </Button>
                                    </Stack>
                                </Container>
                            </Container>
                        </Paper>
                    </Container>
                </Modal>
                <MapContainer style={{ height: '50vh' }} center={[city.positionX, city.positionY]} zoom={10.75} scrollWheelZoom={true}>
                    <TileLayer
                        url='https://api.mapbox.com/styles/v1/stepoutdatesapp/clfps05ar000301mxgh162z2v/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RlcG91dGRhdGVzYXBwIiwiYSI6ImNsZnByeG9wdzE4bGEzc25ydWE5amw5cnoifQ.LN-fAbzv32FCeKmf_gY2Xw'
                        attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>"
                    />
                    {filterLocations.map(location =>
                        <Marker position={[location.positionX, location.positionY]}>
                            <Popup>
                                <Link to={'/details?location=' + location.id} style={{ textDecoration: 'none', color: 'black' }}>
                                    {location.name} <br /> {location.type} <br /> {location.address}
                                </Link>
                            </Popup>
                        </Marker>
                    )}
                </MapContainer>
            </Container>
            <Results filterLocations={filterLocations} />
            <Footer />
        </>
    );
}

// mapbox://styles/stepoutdatesapp/clfps05ar000301mxgh162z2v

// pk.eyJ1Ijoic3RlcG91dGRhdGVzYXBwIiwiYSI6ImNsZnByeG9wdzE4bGEzc25ydWE5amw5cnoifQ.LN-fAbzv32FCeKmf_gY2Xw

// https://api.mapbox.com/styles/v1/stepoutdatesapp/clfps05ar000301mxgh162z2v/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic3RlcG91dGRhdGVzYXBwIiwiYSI6ImNsZnByeG9wdzE4bGEzc25ydWE5amw5cnoifQ.LN-fAbzv32FCeKmf_gY2Xw

export default MapPage;

