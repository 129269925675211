import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {
    Divider,
    Card,
    CardMedia,
    CardContent,
    Grid,
    Pagination
}
    from '@mui/material';

import { API, Storage } from 'aws-amplify';


import { Link } from "react-router-dom";

const LocationCard = (props) => {

    const [location, setLocation] = useState('');

    useEffect(() => {
        async function fetchImage() {
            //console.log(props.location);
            //const temp = props.location;
            //console.log(temp.image);
            const imageKey = await Storage.get(props.location.image);
            //console.log(imageKey);
            //temp.image = imageKey;
            setLocation(imageKey);
        }
        fetchImage()
    }, [props])

    return (
        <Container sx={{ pb: .5, pt: 1 }}>
            <Link to={'/details?location=' + props.location.id} style={{ textDecoration: 'none' }}>

                <Card
                    sx={{
                        display: 'flex',
                        //padding: 2, // Adjust padding on mobile
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'center', sm: 'flex-start' }, // Vertically align items on mobile
                    }}
                >
                    {/* Left section */}
                    {/* Image */}
                    <CardMedia
                        component="img"
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '15vh',
                            width: { xs: '100%', sm: 100 },
                            height: { xs: 'auto', sm: 75 },
                        }}
                        image={location}
                        alt="Location Pic"
                    />

                    {/* Middle section */}
                    <CardContent
                        sx={{
                            flex: '1 0 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center', // Center content on mobile
                            textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile
                            minHeight: { xs: 75, sm: 'auto' }, // Ensure minimum height on mobile
                        }}
                    >
                        <div style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
                            <Typography variant="h6" component="div">
                                {props.location.name} {/* Prevent text from overflowing */}
                            </Typography>
                        </div>
                        <Typography variant="body2" color="text.secondary" noWrap>
                            {props.location.type} {/* Prevent text from overflowing */}
                        </Typography>
                    </CardContent>
                </Card>
            </Link>
        </Container>
    );
};

export default function Results(props) {

    
    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };    
    
    return (
        <Container maxWidth="lg" align='left' sx={{ pb: 2 }}>

            <Typography variant='h4' sx={{ pt: 2}} align='center'>
                Results
            </Typography>
            <Divider />

            {props.filterLocations.slice(((page - 1) * 10), (page * 10)).map(location =>
                <LocationCard location={location} />
            )}

            <Pagination sx={{pt: 1}} count={(Math.ceil(props.filterLocations.length / 10))} page={page} onChange={handleChange} />

        </Container>
    );

}