import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import NavBar from "../components/NavBar.js";
import Footer from "../components/Footer.js";

import { Container, Typography } from '@mui/material';

import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';

function CitySelect() {

    const [cities, setCities] = useState([]);

    useEffect(() => {
        async function fetchCities() {
            try {
                const citiesData = await API.graphql({ query: queries.listCities });
                const temp = citiesData.data.listCities.items;
                setCities(temp);
            } catch (err) {
                console.log({ err })
            }
        }
        fetchCities()
        console.log(cities);
    }, [])

    return (
        <>
            <NavBar />

            <Container maxWidth='sm' align='center'>
                <Typography variant='h2' sx={{ pt: 4, pb: 2 }}>
                    Select City
                </Typography>
            </Container>

            <Container maxWidth='md' align='center'>
                {cities.map(city =>
                    // <Link to={'/map?city=' + city.id} style={{ textDecoration: 'none' }}>
                    <Link to={'/map?city=' + city.id} style={{ color: 'black',  display: 'inline-block' }}>
                        <Typography variant='h5' sx={{ pt: 4, pb: 2 }}>{city.name}</Typography>
                    </Link>
                )}

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Typography>
                    ~ Check back for more cities coming soon! ~
                </Typography>
            </Container>
            <Footer/>
        </>
    );
}

export default CitySelect;