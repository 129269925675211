import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from "../../components/NavBar.js";
import Footer from "../../components/Footer.js";
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
    CardMedia,
    CardContent,
    Modal
} from '@mui/material/';
import * as queries from '../../graphql/queries.js';
import { API, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function AccountSettings() {

    const [user, setUser] = useState({});
    const [deleting, setDeleting] = useState(false);

    // Modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // Modal 2
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    useEffect(() => {
        async function getUser() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user);
            } catch (err) {
                console.log({ err })
            }
        }
        getUser()
    }, [])

    async function beginDelete() {
        setDeleting(true);
        handleOpen();
    }

    async function executeDelete() {
        handleClose();
        //deleteUser();
        handleOpen2();
    }

    async function deleteUser() {
        try {
            const result = await Auth.deleteUser();
            console.log(result);
        } catch (error) {
            console.log('Error deleting user', error);
        }
    }

    return (
        <>
            <NavBar />
            <Authenticator>
                {({ signOut, user }) => (
                    <main>
                        <Container maxWidth='md' align='left'>
                            <Typography variant='h2' sx={{ pt: 4, pb: 2 }}>
                                My Settings
                            </Typography>
                            <Stack direction='column' spacing={3}>
                                <Link to='/profile' style={{ textDecoration: 'none' }}>
                                    <Button style={{ width: '100%' }} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Back</Button>
                                </Link>
                                <Link to='/editprofile' style={{ textDecoration: 'none' }}>
                                    <Button style={{ width: '100%' }} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Edit Profile</Button>
                                </Link>
                                <Button style={{ width: '100%' }} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Request My Data</Button>
                                <Button onClick={beginDelete} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Delete Account</Button>
                            </Stack>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Are you sure?
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2, pb: 2 }}>
                                        Once you delete your account, all of your data is wiped and can't be recovered.
                                    </Typography>
                                    <Stack direction='row' spacing={2}>
                                        <Button onClick={() => executeDelete()} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Delete Account</Button>
                                        <Button onClick={handleClose} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Cancel</Button>
                                    </Stack>
                                </Box>
                            </Modal>
                            <Modal
                                open={open2}
                                onClose={handleClose2}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Account successfully deleted.
                                    </Typography>
                                    <Button onClick={handleClose2} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Close</Button>
                                </Box>
                            </Modal>
                        </Container>
                    </main>
                )}
            </Authenticator>
            <Footer />
        </>
    );
}

export default AccountSettings;