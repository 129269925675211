// src/components/OnePost.js

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

import {
  Stack,
  Button,
  Typography,
  Paper,
  Grid,
  Card,
  Container,
  Box,
  CardMedia,
  CardContent
} from '@mui/material/';

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <div>
      <NavBar />
      <Container maxWidth='md'>
        <Container align='center'>
            <Typography sx={{ pt: 5 }} variant='h4' align='center' >{postData.title}</Typography>

            <Typography sx={{ pb: 2 }} variant='h6' align='center' >{postData.name}</Typography>

          {/* <img src={urlFor(postData.mainImage).width(200).url()} alt="" /> */}

          <Box
            component="img"
            display='flex'
            sx={{
              minHeight: "15vh",
              minWidth: "10vw",
              maxHeight: "50vh",
              maxWidth: "50vw",
            }}
            src={urlFor(postData.mainImage)}
          />
        </Container>

        <div>
          <BlockContent
            blocks={postData.body}
            projectId={client.projectId}
            dataset={client.dataset}
          />
        </div>
      </Container>
      <Footer />

    </div>
  );
}