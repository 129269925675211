// src/components/AllPosts.js

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client.js";
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
    CardMedia,
    CardContent,
    CardHeader
} from '@mui/material/';

import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

export default function AllPosts() {
    const [allPostsData, setAllPosts] = useState(null);
    const [firstPost, setFirstPost] = useState(true);

    useEffect(() => {
        client.fetch('*[_type == "post"] | order(_createdAt desc)')
            // .then((data) => console.log(data))
            .then((data) => setAllPosts(data))
            .catch(console.error);

        console.log()
    }, []);

    return (
        <body>
            <div>
                {allPostsData &&
                    allPostsData.map((post, index) => (
                        <>
                            <Container sx={{ pb: 1, pt: 1, maxWidth: '95%' }}>
                                <Link to={"/" + post.slug.current} key={post.slug.current} style={{ textDecoration: 'none', color: 'black', maxWidth: '95%' }}>

                        

                                    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardMedia
                                            component="img"
                                            image={urlFor(post.mainImage).url()}
                                            sx={{
                                                maxWidth: '100%',
                                                maxHeight: '30vh',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <CardHeader title={post.title} />
                                        <CardContent>
                                            <p>{new Date(post.publishedAt).toLocaleDateString()}</p>
                                        </CardContent>
                                    </Card>


                                </Link>
                                
                            </Container>
                        </>
                    ))}
            </div>
        </body >

    );
}