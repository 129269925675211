import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { Link } from "react-router-dom";

export default function Greeting() {
    return (
        <Container maxWidth="sm" align='center' sx={{pb: 4}}>

            <Typography variant='h3' sx={{ pt: 2 }}>
                Plan your next date with us
            </Typography>
            <Typography variant='h6' sx={{ pt: 2, pb: 4 }}>
                Finding the perfect date spot can be tough - let us be your guide to new and exciting romantic destinations
            </Typography>

            <Link to='/cityselect' style={{ textDecoration: 'none' }}>
                <Button sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained' color='secondary'><b>Select City</b></Button>
            </Link>

        </Container>
    );
}