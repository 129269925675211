/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      positionX
      positionY
      locations {
        items {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        positionX
        positionY
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      blurb
      image
      type
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          locationPostsId
        }
        nextToken
      }
      address
      positionX
      positionY
      city {
        id
        name
        positionX
        positionY
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      alcohol
      price
      indoorOutdoor
      active
      website
      tags
      createdAt
      updatedAt
      cityLocationsId
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        blurb
        image
        type
        posts {
          nextToken
        }
        address
        positionX
        positionY
        city {
          id
          name
          positionX
          positionY
          createdAt
          updatedAt
        }
        alcohol
        price
        indoorOutdoor
        active
        website
        tags
        createdAt
        updatedAt
        cityLocationsId
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      location {
        id
        name
        description
        blurb
        image
        type
        posts {
          nextToken
        }
        address
        positionX
        positionY
        city {
          id
          name
          positionX
          positionY
          createdAt
          updatedAt
        }
        alcohol
        price
        indoorOutdoor
        active
        website
        tags
        createdAt
        updatedAt
        cityLocationsId
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      locationPostsId
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        location {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        locationPostsId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      post {
        id
        title
        location {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        locationPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          title
          createdAt
          updatedAt
          locationPostsId
        }
        content
        createdAt
        updatedAt
        postCommentsId
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      title
      blurb
      body
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        blurb
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPowerUser = /* GraphQL */ `
  query GetPowerUser($id: ID!) {
    getPowerUser(id: $id) {
      id
      email
      cognitoSub
      createdAt
      updatedAt
    }
  }
`;
export const listPowerUsers = /* GraphQL */ `
  query ListPowerUsers(
    $filter: ModelPowerUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPowerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        cognitoSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
