import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

import ReactGA from 'react-ga4';

const TRACKING_ID = "G-T2Q9E1QJ9T"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Amplify.configure(awsconfig);

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      paper: '#f9f9f9',
    },
  },
  typography: {
    fontFamily: [
      'Albert Sans',
      'sans-serif',
    ].join(',')
  },
  radio: {
    "&.Mui-checked": {
      color: "black"
    }
  },
  checked: {}
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
