import * as React from 'react';
import { Link } from 'react-router-dom';

import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
} from '@mui/material/';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer() {
    return (
        <Paper sx={{
            marginTop: '5%',
            position: 'static',
            width: '100%',
            bottom: 0,
        }} component="footer" square variant="outlined" style={{ background: '#F4F4F4' }}>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        my: 1
                    }}
                >
                    <div>
                        <Stack direction='row' spacing={2}>
                            <Link to="https://twitter.com/stepoutdates">
                                <Typography><TwitterIcon /></Typography>
                            </Link>
                            <Typography><FacebookIcon /></Typography>
                            <Link to="https://www.instagram.com/stepoutdates/">
                                <Typography><InstagramIcon /></Typography>
                            </Link>
                        </Stack>
                    </div>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        mb: 2,
                    }}
                >
                    <Stack direction='column'>
                        <Typography variant="caption" color="initial" align='center'>
                            Date Spotters - 2023
                        </Typography>
                        <Link to='/contact' style={{ textDecoration: 'none' }} align='center'>
                            <Typography variant="caption" color="initial" align='center'>
                                Contact Us
                            </Typography>
                        </Link>
                    </Stack>
                </Box>
            </Container>
        </Paper>
    );
}