import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import NavBar from "../components/NavBar.js";
import Footer from "../components/Footer.js";

import { Container, Typography, Box } from '@mui/material';

import * as queries from '../graphql/queries';
import { API, Storage } from 'aws-amplify';

function CitySelect() {

    const [cities, setCities] = useState([]);
    const [location, setLocation] = useState([]);

    const query = new URLSearchParams(window.location.search);
    const locationID = query.get("location")

    useEffect(() => {
        async function fetchLocation() {
            try {
                const oneLocation = await API.graphql({
                    query: queries.getLocation,
                    variables: { id: locationID }
                });
                const temp = oneLocation.data.getLocation;
                const imageKey = await Storage.get(temp.image);
                temp.image = imageKey;
                setLocation(temp);
                // setLoading(false);
            } catch (err) {
                console.log({ err })
            }
        }
        fetchLocation()
    }, [])

    return (
        <>
            <NavBar />
            {/* ------------------- */}

            <Container maxWidth='lg' align='center'>
                <Typography variant='h3' sx={{ pt: 4 }} align='center'>
                    {location.name}
                </Typography>
                <Typography variant='subtitle1' align='center'>
                    {location.address}
                </Typography>
                <Typography variant='subtitle2' sx={{ pb: 3 }} align='center'>
                    <a href={location.website}>{location.website}</a>
                </Typography>

                <Box
                    component="img"
                    sx={{
                        height: 350,
                        width: 350,
                        maxHeight: { xs: 350, md: 250 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    src={location.image}
                />

                <Typography variant='body1' sx={{ pt: 4, pb: 2 }} align='left'>
                    {location.description}
                </Typography>
            </Container>

            <Container maxWidth='md'>
                {cities.map(city =>
                    <Link to={'/map?city=' + city.id} style={{ textDecoration: 'none' }}>
                        <Typography variant='h6' sx={{ pt: 4, pb: 2 }}>- {city.name}</Typography>
                    </Link>
                )}
            </Container>

            {/* ------------------- */}
            <Footer />
        </>
    );
}

export default CitySelect;