import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import NavBar from "../components/NavBar.js";
import Footer from "../components/Footer.js";

import { Container, Typography, Divider } from '@mui/material';

import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';

function ContactPage() {

    useEffect(() => {

    }, [])

    
    return (
        <>
            {/* <head>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6712762099510460"
                    crossorigin="anonymous"></script>
            </head> */}
            <NavBar />

            <Container maxWidth='sm' align='left'>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    Contact Us
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    Email: stepoutdatesapp@gmail.com
                </Typography>
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    Our team will respond as promptly as possible
                </Typography>
            </Container>

            <Footer />
        </>
    );
}

export default ContactPage;