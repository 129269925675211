import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import NavBar from "../components/NavBar.js";
import Footer from "../components/Footer.js";

import { Container, Typography, Divider } from '@mui/material';

import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';

function AboutPage() {

    useEffect(() => {

    }, [])

    
    return (
        <>
            {/* <head>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6712762099510460"
                    crossorigin="anonymous"></script>
            </head> */}
            <NavBar />

            <Container maxWidth='sm' align='left'>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    About Us
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    Welcome to Date Spotters, your ultimate destination for planning unforgettable dates with your significant other!
                    We understand that creating memorable moments with your loved one is a special endeavor, and we're here to make
                    it easier and more enjoyable than ever before.
                </Typography>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    Our Story
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    At  Date Spotters, we believe that love is an adventure waiting to be explored, and that every date
                    should be an opportunity to deepen your connection and create beautiful memories together. Our journey
                    began with a simple idea: to provide a platform where couples can discover, plan, and experience incredible dates,
                    effortlessly.
                    <br />
                    <br />
                    Founded by a team of passionate individuals who are dedicated to strengthening relationships,
                    Date Spotters was born out of a desire to simplify the process of finding the perfect date spot.
                    We've personally experienced the joys and challenges of nurturing relationships, and we wanted to
                    share our knowledge and expertise with others on the same journey.
                </Typography>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    Our Mission
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    Our mission is clear: to empower couples to nurture their love, spark new connections, and cherish
                    moments that last a lifetime. We're committed to helping you discover the ideal date location,
                    whether it's a cozy restaurant, a scenic park, a vibrant bar, or any other
                    setting that aligns with your preferences.
                </Typography>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    What Sets Us Apart
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    What sets Date Spotters apart is our dedication to delivering an unparalleled dating experience.
                    Here's what you can expect when you choose us:
                    <ol>
                        <li>
                            <b>Extensive Selection: </b>We've curated a vast database of date locations,
                            ranging from romantic restaurants and charming cafes to adventurous outdoor
                            activities, ensuring there's something for every couple.
                        </li>
                        <li>
                            <b>User-Friendly Interface: </b>Our website is designed with you in mind.
                            It's intuitive, easy to navigate, and equipped with powerful filtering options,
                            allowing you to find the perfect date spot tailored to your preferences.
                        </li>
                        <li>
                            <b>Inspiration and Tips: </b>In addition to helping you find places, we provide
                            inspiration, ideas, and expert tips on how to make each date truly special.
                        </li>
                        <li>
                            <b>Community and Support: </b>Join our community of fellow romantics who
                            share their experiences, recommendations, and insights. We're here to support you
                            on your journey to stronger, more meaningful relationships.
                        </li>
                    </ol>
                </Typography>
                <Typography variant='h5' sx={{ pt: 4, pb: 1 }}>
                    Join Us on the Journey of Love
                </Typography>
                <Divider />
                <br />
                <Typography variant='p' sx={{ pt: 4 }}>
                    Thank you for choosing Date Spotters as your trusted partner in crafting unforgettable
                    date experiences. Whether you're celebrating an anniversary, planning a surprise date, or
                    simply looking to rekindle the flame, we're here to guide you every step of the way.
                    <br />
                    <br />
                    Let's embark on this beautiful journey of love together. Start exploring, start connecting,
                    and start making memories that will last a lifetime. At Date Spotters, we're here
                    to help you create your own love story, one unforgettable date at a time.
                    <br />
                    <br />
                    With love and excitement,
                    <br />
                    The Date Spotters Team
                </Typography>
            </Container>

            <Footer />
        </>
    );
}

export default AboutPage;