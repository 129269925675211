// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { City, Location, Post, Comment, Blog, PowerUser } = initSchema(schema);

export {
  City,
  Location,
  Post,
  Comment,
  Blog,
  PowerUser
};