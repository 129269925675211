import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from "../../components/NavBar.js";
import Footer from "../../components/Footer.js";
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
    CardMedia,
    CardContent,
    Modal
} from '@mui/material/';
import * as queries from '../../graphql/queries.js';
import { API, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Profile() {

    const [user, setUser] = useState({});

    useEffect(() => {
        async function getUser() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user);
            } catch (err) {
                console.log({ err })
            }
        }
        getUser()
    }, [])

    return (
        <>
            <NavBar />
            <Authenticator>
                {({ signOut, user }) => (
                    <main>
                        <Container maxWidth='md' align='left'>
                            <Typography variant='h2' sx={{ pt: 4, pb: 2 }}>
                                My Profile
                            </Typography>
                            <Typography variant='h6' sx={{pb: 4 }}>
                                You are registered with email: {user.attributes.email}
                            </Typography>
                            <Stack direction='column' spacing={2}>
                                <Link to='/accountsettings' style={{ textDecoration: 'none'}}>
                                    <Button style={{width: '100%'}} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Account Settings</Button>
                                </Link>
                                <Button onClick={signOut} sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained'>Sign out</Button>
                            </Stack>
                        </Container>
                    </main>
                )}
            </Authenticator>
            <Footer />
        </>
    );
}

export default Profile;