import { useState, useEffect } from 'react';
import { Box, Stack, FormControl, InputLabel, OutlinedInput, InputAdornment, MenuItem, Chip, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { v4 as uuid } from 'uuid';
import NavBar from "../../components/NavBar.js";
import { useTheme } from '@mui/material/styles';


import { Storage, API, Auth } from 'aws-amplify';
import { createLocation } from '../../graphql/mutations';

import { Link } from "react-router-dom";
import * as queries from '../../graphql/queries';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const tags = [
    'Restaurant',
    'Bar',
    'Arcade',
    'Sporty',
    'Mediterranean',
    'Italian',
    'Wine',
    'Park',
    'Games',
    'American',
    'Indian',
    'Asian',
    'Puzzles',
    'First Date',
    'Museum',
    'Zoo',
    'None'
];

function getStyles(tag, tagArray, theme) {
    return {
        fontWeight:
            tagArray.indexOf(tag) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const initialState = {
    name: '',
    image: {},
    file: '',
    description: '',
    blurb: '',
    positionX: '',
    positionY: '',
    address: '',
    alcohol: '',
    price: '',
    indoorOutdoor: '',
    active: '',
    website: '',
    type: '',
    tags: [],
};

export default function AddLocation() {

    const theme = useTheme();

    const [formState, updateFormState] = useState(initialState);
    const [city, setCity] = useState('');
    const [cities, setCities] = useState([]);
    const [tagArray, setTagArray] = useState([]);


    useEffect(() => {
        async function fetchCities() {
            try {
                const citiesData = await API.graphql({ query: queries.listCities });
                const temp = citiesData.data.listCities.items;
                setCities(temp);
            } catch (err) {
                console.log({ err })
            }
        }
        fetchCities()
    }, [])

    const handleChange = (event) => {
        //event.persist();
        const {
            target: { value },
        } = event;
        setTagArray(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        updateFormState(currentState => ({ ...currentState, tags: (typeof value === 'string' ? value.split(',') : value) }));
        console.log(typeof value === 'string' ? value.split(',') : value);
    };

    function onChangeText(e) {
        e.persist();
        updateFormState(currentState => ({ ...currentState, [e.target.name]: e.target.value }));
    };

    function onChangeFile(e) {
        e.persist();
        if (!e.target.files[0]) return;
        const fileExtPosition = e.target.files[0].name.search(/.png|.jpg|.gif/i);
        const firstHalf = e.target.files[0].name.slice(0, fileExtPosition);
        const secondHalf = e.target.files[0].name.slice(fileExtPosition);
        const fileName = firstHalf + "_" + uuid() + secondHalf;
        const image = { fileInfo: e.target.files[0], name: fileName };
        updateFormState(currentState => ({ ...currentState, file: URL.createObjectURL(e.target.files[0]), image }))
    }

    async function save() {
        try {
            console.log(city);
            const { name, image, description, blurb, positionX, positionY, address, alcohol, price, indoorOutdoor, active, website, type, tags } = formState;
            //if (!name || !image) return;
            updateFormState(currentState => ({ ...currentState, saving: true }));
            const locationInfo = { name, image: formState.image.name, description, blurb, positionX, positionY, address, cityLocationsId: city, alcohol, price, indoorOutdoor, active, website, type, tags };
            console.log(locationInfo);
            await Storage.put(formState.image.name, formState.image.fileInfo);
            await API.graphql({
                query: createLocation, variables: { input: locationInfo }
            });
            // updateInventoryItems([...inventoryItems, { ...inventoryItemInfo, image: formState.file }]);
            updateFormState(currentState => ({ ...currentState, saving: false }));
            // updateOverlayVisibility2(false);
        } catch (err) {
            console.log('error: ', err);
        }
    }

    return (
        <>
            <NavBar />
            <Container maxWidth="md" align='left' sx={{ pb: 5 }}>



                <Typography variant='h2' sx={{ pt: 4, pb: 2 }} color='black'>
                    Add Location
                </Typography>


                <Stack direction='column' spacing={2}>
                    {/* CITY */}
                    <FormControl >
                        <InputLabel color='secondary'>City</InputLabel>
                        <Select
                            //value={city.id}
                            label="City"
                            onChange={(e) => setCity(e.target.value)}
                            color='secondary'
                        >
                            {cities.map(city =>
                                <MenuItem color='secondary' value={city.id}>{city.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {/* NAME */}
                    <TextField
                        placeholder="Name"
                        name="name"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* ADDRESS */}
                    <TextField
                        placeholder="Address"
                        name="address"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* Blurb */}
                    <TextField
                        placeholder="Blurb"
                        name="blurb"
                        onChange={onChangeText}
                        multiline
                        rows={2}
                        color='secondary'
                    />
                    {/* DESCRIPTION */}
                    <TextField
                        placeholder="Description"
                        name="description"
                        onChange={onChangeText}
                        //label="Description"
                        multiline
                        rows={5}
                        color='secondary'
                    />
                    {/* POSITION X */}
                    <TextField
                        placeholder="X-Position (Latitude)"
                        name="positionX"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* POSITION Y */}
                    <TextField
                        placeholder="Y-Position (Longitude)"
                        name="positionY"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* ALCOHOL */}
                    <TextField
                        placeholder="Alcohol (Yes/No)"
                        name="alcohol"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* INDOOR/OUTDOOR */}
                    <TextField
                        placeholder="Indoor/Outdoor (Indoor/Outdoor)"
                        name="indoorOutdoor"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* ACTIVE*/}
                    <TextField
                        placeholder="Active (Yes/No)"
                        name="active"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* PRICE */}
                    <TextField
                        placeholder="Price (1,2,3,4)"
                        name="price"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* WEBSITE */}
                    <TextField
                        placeholder="Website"
                        name="website"
                        onChange={onChangeText}
                        color='secondary'
                    />
                    {/* TYPE */}
                    <TextField
                        placeholder="Type (Restaurant, Bar, etc.)"
                        name="type"
                        onChange={onChangeText}
                        color='secondary'
                    />

                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={tagArray}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {tags.map((tag) => (
                                <MenuItem
                                    key={tag}
                                    value={tag}
                                    style={getStyles(tag, tagArray, theme)}
                                >
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Stack>

                <br />

                <Typography variant='h6' sx={{ pt: 1, pb: 2 }} color='black'>Image Selection</Typography>
                <input
                    type="file"
                    onChange={onChangeFile}
                />

                <br />
                <br />

                <Button sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained' onClick={save}>Add Location</Button>

            </Container>
        </>
    );
}