import React, { useState, useEffect } from 'react';

import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

import '../../App.css';
import { Link } from 'react-router-dom';

import * as queries from '../../graphql/queries';
import { API } from 'aws-amplify';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'

import { ThemeProvider, createTheme } from '@mui/material/styles'
import AllPosts from './AllPosts';
import {
    Stack,
    Button,
    Typography,
    Paper,
    Grid,
    Card,
    Container,
    Box,
} from '@mui/material/';
import { fontSize } from '@mui/system';

import { client } from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";

// GOOGLE ADSENSE
import Ad from '../../Ad';

const builder = imageUrlBuilder(client);
function urlFor(source) {
    return builder.image(source);
}

function Blog() {

    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        async function fetchBlogs() {
            try {
                const blogsData = await API.graphql({ query: queries.listBlogs });
                const temp = blogsData.data.listBlogs.items;
                setBlogs(temp);
            } catch (err) {
                console.log({ err })
            }
        }
        fetchBlogs()
        console.log(blogs);
    }, [])

    return (
        <>
            <NavBar />
            <Container maxWidth='md'>
                <Typography size='lg' variant='h2' align='left' sx={{ pt: 1, pb: 2 }}>
                    Blog
                </Typography>
                {/* <Ad slot="9166920954"/> */}
                <Stack spacing={3} alignItems='center'>
                    <AllPosts />
                </Stack>
            </Container>
            <Footer />
        </>
    );
}

export default Blog;
