import NavBar from "../components/NavBar";
import Greeting from "../components/Greeting";
import Footer from "../components/Footer";
import Info from "../components/Info";

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { Container, Typography, Divider, Button, Stack } from '@mui/material';
import { Link } from "react-router-dom";


function HomePage() {

    const theme = useTheme();

    return (
        <>
            <ThemeProvider theme={theme}>
                <NavBar />
                <br />
                <Greeting />
                <Info />
                <Container maxWidth='md' sx={{ pt: 12, pb: 2 }}>
                    <Stack direction='column' spacing={1} alignItems='center'>
                        <Typography align='center' variant='h5'>
                            Make sure to check out our blog for more date ideas!
                        </Typography>
                        <Link to='/blog' style={{ textDecoration: 'none' }}>
                            <Button sx={{ pt: 2, pb: 2, pl: 2, pr: 2, borderRadius: 6 }} size='large' variant='contained' color='secondary'>
                                <b>Blog</b>
                            </Button>
                        </Link>
                    </Stack>
                </Container>
                <Footer />
            </ThemeProvider>
        </>
    );
}

export default HomePage;