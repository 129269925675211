import { useState, useEffect } from 'react';
import { Box, Stack, FormControl, InputLabel, OutlinedInput, InputAdornment, Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { v4 as uuid } from 'uuid';
import NavBar from "../../components/NavBar.js";

import { Storage, API, Auth } from 'aws-amplify';
import { createCity } from '../../graphql/mutations';

import { Link } from "react-router-dom";
import * as queries from '../../graphql/queries';

const initialState = {
    name: '',
};

export default function AddCity() {

    const [formState, updateFormState] = useState(initialState);

    function onChangeText(e) {
        e.persist();
        updateFormState(currentState => ({ ...currentState, [e.target.name]: e.target.value }));
    };

    async function save() {
        console.log(formState);
        try {
            const { name } = formState;
            updateFormState(currentState => ({ ...currentState, saving: true }));
            const cityInfo = { name };
            //await Storage.put(formState.image.name, formState.image.fileInfo);
            await API.graphql({
                query: createCity, variables: { input: cityInfo }
            });
            updateFormState(currentState => ({ ...currentState, saving: false }));
        } catch (err) {
            console.log('error: ', err);
        }
    }

    return (
        <>
            <NavBar />
            <Container maxWidth="md" align='left' sx={{ pb: 5 }}>

                <Typography variant='h2' sx={{ pt: 4, pb: 2 }} color='black'>
                    Add City
                </Typography>

                <Stack direction='column' spacing={2}>
                    {/* NAME */}
                    <TextField
                        placeholder="Name"
                        name="name"
                        onChange={onChangeText}
                        color='secondary'
                    />
                </Stack>
                <br />
                <br />
                <Button sx={{ pt: 2, pb: 2, borderRadius: 6 }} size='large' variant='contained' onClick={save}>Add City</Button>

            </Container>
        </>
    );
}