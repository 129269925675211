import * as React from 'react';

import {
    Box,
    Typography,
    Button,
    Container,
    Stack,
    Grid,
    Flex
} from '@mui/material';

import {
    HeroLayout2
} from '../ui-components';

import { Link } from "react-router-dom";

export default function Info() {

    const contentContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
    };

    return (
        <>
            <Grid container style={{ flexGrow: 1, backgroundColor: '#F1F1F1' }}>
                <Grid item xs={12} sm={6} sx={{ pl: 4, pr: 2, pt: 2, pb: 3 }}>
                    <div style={contentContainerStyle}>
                        <Typography variant="h4" component="h4" gutterBottom='true'>
                            It's easy to use - give it a try
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ pb: 2.5 }}>
                            All you need to do to find amazing date ideas is select the city you’re in, choose your date criteria using our filters, then see what comes up!
                        </Typography>
                        <Container align='center' justifyContent='center'>
                            <Link to='/cityselect' style={{ textDecoration: 'none' }}>
                                <Button variant="contained" color="primary" size='large'>
                                    Get Started
                                </Button>
                            </Link>
                        </Container>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt="The house from the offer."
                        src={require("../assets/people-gce9bdad63_1280.jpg")}
                    />
                </Grid>
            </Grid>
        </>

        // <Box
        //     sx={{
        //         width: '100%',
        //         height: 300,
        //         backgroundColor: '#000000',
        //         // '&:hover': {
        //         //     backgroundColor: 'primary.main',
        //         //     opacity: [0.9, 0.8, 0.7],
        //         // },
        //     }}
        // />
    );
}