/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      positionX
      positionY
      locations {
        items {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      positionX
      positionY
      locations {
        items {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      positionX
      positionY
      locations {
        items {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      description
      blurb
      image
      type
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          locationPostsId
        }
        nextToken
      }
      address
      positionX
      positionY
      city {
        id
        name
        positionX
        positionY
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      alcohol
      price
      indoorOutdoor
      active
      website
      tags
      createdAt
      updatedAt
      cityLocationsId
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      description
      blurb
      image
      type
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          locationPostsId
        }
        nextToken
      }
      address
      positionX
      positionY
      city {
        id
        name
        positionX
        positionY
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      alcohol
      price
      indoorOutdoor
      active
      website
      tags
      createdAt
      updatedAt
      cityLocationsId
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      description
      blurb
      image
      type
      posts {
        items {
          id
          title
          createdAt
          updatedAt
          locationPostsId
        }
        nextToken
      }
      address
      positionX
      positionY
      city {
        id
        name
        positionX
        positionY
        locations {
          nextToken
        }
        createdAt
        updatedAt
      }
      alcohol
      price
      indoorOutdoor
      active
      website
      tags
      createdAt
      updatedAt
      cityLocationsId
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      title
      location {
        id
        name
        description
        blurb
        image
        type
        posts {
          nextToken
        }
        address
        positionX
        positionY
        city {
          id
          name
          positionX
          positionY
          createdAt
          updatedAt
        }
        alcohol
        price
        indoorOutdoor
        active
        website
        tags
        createdAt
        updatedAt
        cityLocationsId
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      locationPostsId
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      title
      location {
        id
        name
        description
        blurb
        image
        type
        posts {
          nextToken
        }
        address
        positionX
        positionY
        city {
          id
          name
          positionX
          positionY
          createdAt
          updatedAt
        }
        alcohol
        price
        indoorOutdoor
        active
        website
        tags
        createdAt
        updatedAt
        cityLocationsId
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      locationPostsId
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      title
      location {
        id
        name
        description
        blurb
        image
        type
        posts {
          nextToken
        }
        address
        positionX
        positionY
        city {
          id
          name
          positionX
          positionY
          createdAt
          updatedAt
        }
        alcohol
        price
        indoorOutdoor
        active
        website
        tags
        createdAt
        updatedAt
        cityLocationsId
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      locationPostsId
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        location {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        locationPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        location {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        locationPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      post {
        id
        title
        location {
          id
          name
          description
          blurb
          image
          type
          address
          positionX
          positionY
          alcohol
          price
          indoorOutdoor
          active
          website
          tags
          createdAt
          updatedAt
          cityLocationsId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        locationPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      title
      blurb
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      title
      blurb
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      title
      blurb
      body
      createdAt
      updatedAt
    }
  }
`;
export const createPowerUser = /* GraphQL */ `
  mutation CreatePowerUser(
    $input: CreatePowerUserInput!
    $condition: ModelPowerUserConditionInput
  ) {
    createPowerUser(input: $input, condition: $condition) {
      id
      email
      cognitoSub
      createdAt
      updatedAt
    }
  }
`;
export const updatePowerUser = /* GraphQL */ `
  mutation UpdatePowerUser(
    $input: UpdatePowerUserInput!
    $condition: ModelPowerUserConditionInput
  ) {
    updatePowerUser(input: $input, condition: $condition) {
      id
      email
      cognitoSub
      createdAt
      updatedAt
    }
  }
`;
export const deletePowerUser = /* GraphQL */ `
  mutation DeletePowerUser(
    $input: DeletePowerUserInput!
    $condition: ModelPowerUserConditionInput
  ) {
    deletePowerUser(input: $input, condition: $condition) {
      id
      email
      cognitoSub
      createdAt
      updatedAt
    }
  }
`;
