import './App.css';

import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import '@aws-amplify/ui-react/styles.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import HomePage from './pages/HomePage';
import CitySelect from './pages/CitySelect';
import MapPage from './pages/MapPage';
import Blog from './pages/Blog/Blog';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import LocationPage from './pages/LocationPage';
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';
import AccountSettings from './pages/Profile/AccountSettings';
import AdminHome from './pages/Admin/AdminHome'
import AddLocation from './pages/Admin/AddLocation';
import AddCity from './pages/Admin/AddCity';
import AllPosts from "./pages/Blog/AllPosts.js";
import OnePost from "./pages/Blog/OnePost.js";

import ReactGA from 'react-ga4';
import React, { useState, useEffect } from 'react';

const TRACKING_ID = "G-T2Q9E1QJ9T"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    ReactGA.send("pageview")
    //console.log('sending!');
  }, []);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
      },
      background: {
        paper: '#f9f9f9',
      },
    },
    typography: {
      fontFamily: [
        'Albert Sans',
        'sans-serif',
      ].join(',')
    }
  });
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cityselect" element={<CitySelect />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/details" element={<LocationPage />} />
        <Route path="/:slug" element={<OnePost />} />
        <Route path="/allposts" element={<AllPosts />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin-xyhome" element={<AdminHome />} />
        <Route path="/admin-xyaddlocation" element={<AddLocation />} />
        <Route path="/admin-xyaddcity" element={<AddCity />} />
        <Route path="/accountsettings" element={<AccountSettings />} />
        <Route path="/editprofile" element={<EditProfile />} />
      </Routes>
    </Router>
  );
}

export default App;
