import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Stack';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Link } from 'react-router-dom';
import { Flex } from '@aws-amplify/ui-react';


import { ThemeProvider, useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import Drawer from "./Drawer";
import AppMenu from "./Menu";


export default function NavBar() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const titleStyle = {
        fontSize: isMobile ? '1.5rem' : '1.5rem', // Adjust the sizes as needed
        letterSpacing: isMobile ? '0.5px' : '0.75px',
        wordSpacing: isMobile ? '1px' : '2px',
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" elevation={3}>
                    <Toolbar>
                        <Flex>
                            <Link to='/' style={{ flex: 1, textDecoration: 'none', color: 'black' }}>
                                <Typography variant="h6" component="div" px={2.5} noWrap={true}>
                                    Date Spotters
                                </Typography>
                            </Link>
                        </Flex>


                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />


                        {isMobile ? (
                            // <Drawer/>
                            <AppMenu />
                        ) : (
                            <Flex alignItems={'center'}>
                                <Link to='/cityselect' style={{ textDecoration: 'none', color: 'black' }}>
                                    <Button color="inherit">Cities</Button>
                                </Link>
                                <Link to='/blog' style={{ textDecoration: 'none', color: 'black' }}>
                                    <Button color="inherit">Blog</Button>
                                </Link>
                                <Link to='/about' style={{ textDecoration: 'none', color: 'black' }}>
                                    <Button color="inherit">About</Button>
                                </Link>
                                <Link to='/profile' style={{ textDecoration: 'none', color: 'black' }}>
                                    <AccountCircleIcon />
                                </Link>
                            </Flex>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </ThemeProvider>
    );
}